<template>
  <div class="checkout-component">
    <p class="page-title" style="margin-bottom: 5px !important;">يرجي إختيار داخل أو خارج الرياض</p>
    <!--<div v-html="htmlContent"></div>-->
    <v-card>
      <v-tabs v-model="tab" centered dark grow>
        <v-tab @click="checkTab()" class="hidden-tab" style="display: none">
        </v-tab>
        <v-tab-item>
          <inRyad2 :settings="settings" v-if="settings"
        /></v-tab-item>
        <v-tab @click="checkTab(1)"> الرياض </v-tab>
        <v-tab-item>
          <inRyad :settings="settings" v-if="settings" />
        </v-tab-item>

        <v-tab @click="checkTab(2)"> خارج الرياض </v-tab>

        <v-tab-item>
          <outRyad :settings="settings" v-if="settings" />
        </v-tab-item>

        <v-tab @click="notFound()"> دولى </v-tab>
      </v-tabs>
    </v-card>
    <v-dialog v-model="dialog">
      <v-card class="p-5">
        <v-card-title> </v-card-title>
        <v-card-text class="m-auto">
          عذرًا الشحن الدولي غير متاح حاليًا
        </v-card-text>
        <v-card-actions>
          <button
            @click="
              dialog = false;
              tab = 1;
            "
            class="green-btn"
          >
            موافق
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogOutRyad">
      <v-card class="p-5">
        <v-card-title> </v-card-title>
        <v-card-text class="m-auto">
          عذرًا الشحن خارج الرياض غير متاح حاليًا
        </v-card-text>
        <v-card-actions>
          <button
            @click="
              dialogOutRyad = false;
              tab = 1;
            "
            class="green-btn"
          >
            موافق
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InRyad from "./checkout/InRyad.vue";
import InRyad2 from "./checkout/InRyad2.vue";
import OutRyad from "./checkout/OutRyad.vue";
import { ServiceFactory } from "../services/ServiceFactory";
const aboutService = ServiceFactory.get("about");
export default {
  data() {
    return {
      dialog: false,
      dialogOutRyad: false,
      settings: null,
      tab: null,
      htmlContent: "<title> سعر المنتجات </title>"
    };
  },
  components: {
    // Logo,
    InRyad,
    InRyad2,
    OutRyad,
  },
  methods: {
    checkTab(tab) {
      console.log("tab", tab);
      if (tab == 2) {
        if (
          this.settings.out_ryad_activated == null ||
          this.settings.out_ryad_activated == false
        ) {
          this.tab = 1;
          this.dialogOutRyad = true;
        }
      }
    },
    notFound() {
      this.tab = 1;
      this.dialog = true;
    },
    async fetchSettings() {
      this.dataLoading = true;
      this.settings = await aboutService.getAboutData();
      this.dataLoading = false;
    },
  },
  created() {
    this.fetchSettings();
  },
};
</script>
<style>
.checkout-component .v-slide-group__wrapper {
  background: #fff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  border-radius: 15px;
  color: #5a5a5a;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background: #fff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  border-radius: 15px;
  color: #5a5a5a;
}
.theme--dark.v-tabs > .v-tabs-bar:hover {
  background: #fff;
}
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #5a5a5a !important;
}
.v-tabs-slider {
  display: none;
}
.v-tab--active {
  background: #fed24e;
}
.m-auto {
  margin: auto;
  text-align: center;
}
.green-btn {
  background-color: #5fad66;
  color: #fff;
  padding: 12px;
  border-radius: 5px;
}
.theme--dark.v-tabs .v-tab--active:hover::before,
.theme--dark.v-tabs .v-tab--active::before {
  opacity: 0;
}
.theme--light.v-card {
  background: transparent;
  box-shadow: unset !important;
  color: #5a5a5a !important;
}
.theme--light.v-tabs-items {
  background-color: transparent !important;
  padding: 40px 0;
}
.page-title {
  text-align: center;
  font-weight: 700;
  color: #614c37;
  font-size: 15px;
}
.sar-img{
  height: 15px;
  width: 20px;
  border-style: none;
  border: 2px solid transparent;
  padding: -5px;
  margin: 0px;
  border:0px solid #fff !important;
}
</style>
